import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faImage, faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, connectStateResults } from 'react-instantsearch-dom';

import GrayBar from './components/GrayBar';
import Header from './components/Header';
import GreenBar from './components/GreenBar';
import Footer from './components/Footer';
import Warning from './components/Warning';

import Home from './pages/Home';
import Usc2257 from './pages/Usc2257';

library.add(faCalendarAlt, faImage, faPlayCircle)

function App() {
  return (
    <Router>
    <div className="container">
      <Header />
    </div>
    <div className="container">
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/2257' component={Usc2257} />
        <Route component={Home} />
      </Switch>
      <Footer />
    </div>
    <Warning />
    </Router>
  );
}

export default App;
