import React from 'react';

export default class Usc2257 extends React.Component {
  render() {
    return (
      <div className="mt-5 mb-2 footer">
        <div className="row justify-content-around">
          <div className="col-6 text-center">
            
<p>
18 U.S.C. Section 2257 Compliance Notice<br /><br />

All models, actors, actresses and other persons that appear in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen years at the time of the creation of such depictions.<br /><br />

All other visual depictions displayed on this Website are exempt from the provision of 18 U.S.C. section 2257 and 28 C.F.R. 75 because said visual depictions do not consist of depictions of conduct as specifically listed in 18 U.S.C section 2256 (2) (A) through (D), but are merely depictions of non-sexually explicit nudity, or are depictions of simulated sexual conduct, or are otherwise exempt because the visual depictions were created prior to July 3, 1995.<br /><br />

With respect to all visual depictions displayed on this website, whether of actual sexually explicit conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18 years of age when said visual depictions were created.<br /><br />

The owners and operators of this Website are not the primary producer (as that term is defined in 18 U.S.C. Section 2257 and 28 C.F.R. Part 75) of all the visual content contained on the Website. The Custodian of Records for content on this Website for which the owners and operators of this Website are the primary producer is:<br /><br />
</p>

<p>
KB Productions<br />
3551 St. Charles Blvd. Suite 266<br />
Kirkland, Quebec<br />
H9H 3C4<br />
</p>
          </div>
        </div>
      </div>
    )
  }
}