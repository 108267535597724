import React from 'react';

export default class GrayBar extends React.Component {
  render() {
    return (
        <div className="row justify-content-center graybar py-1">
          <div className="col align-self-center text-center">
            <div className="font-weight-bold text-uppercase">Mikeadriano.com legacy member login</div>
            <div className="font-weight-bold text-lowercase smaller-text">Members that joined before January 1st 2020</div>
            <div>
            <a href="https://www.evilangel.com/en/login" className="btn btn-warning px-4 py-0" target="_blank">CLICK HERE</a>
            </div>
          </div>
        </div>
    )
  }
}
