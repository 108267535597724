import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Input, FormGroup } from 'reactstrap';
import Cookies from 'universal-cookie';

const Warning = (props) => {
  const {
    className
  } = props;

  const cookies = new Cookies();

  let openModal = false;
  if(!cookies.get('ma-warning'))
  {
      openModal = true
  }

  const [modal, setModal] = useState(openModal);

  const toggle = () => setModal(!modal);

  const continueWarning = () => {
    cookies.set('ma-warning', true, { path: '/' });
    setModal(!modal);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} backdrop="static" size="lg" centered={true}>
        <ModalBody>
            <h3 className="text-center">
                THIS SITE CONTAINS SEXUALLY ORIENTED MATERIAL
            </h3>
            <FormGroup>
                <Input type="textarea" name="warning" id="warning" value="The following website, including all webpages, links, images and videos, display sexually explicit material. Only consenting adults are authorized beyond this page. If you are a minor (under the age of 18 years old or 21 years old where 18 isn't the legal age of majority), if sexually explicit material offends you or if it's illegal to view such material in your community, you MUST leave by clicking EXIT below. By ENTERING this site, you agree to these statements: You are an adult in your community and are at least 18 years old (21 in some communities). You will not expose minors to the sexually explicit content you are about to view. You believe that sexually explicit material is not offensive or obscene. Sexually explicit material is not deemed to be obscene or illegal in your community and you wish to view such material. You will use the material available on this website for your own personal use and will not sell, distribute, give or make available the content on this website to anyone. You will take the appropriate steps in order to make sure no minor is able to view the content available on this site. You have read this agreement, understood it and agree to be bound by it." disabled="True" />
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <a href="https://google.com" className="btn btn-danger">Exit</a>
            <Button color="success" onClick={continueWarning}>Continue</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Warning;