import React from 'react';

export default class Header extends React.Component {
  render() {
    return (
      <div className="my-2">
        <div className="row align-items-center h-100 justify-content-around">
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/swallowed.com.png'} alt="Swallowed" className="d-block mx-auto img-fluid" />
          </div>
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/trueanal.com.png'} alt="True Anal" className="d-block mx-auto img-fluid" />
          </div>
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/nympho.com.png'} alt="Nympho" className="d-block mx-auto img-fluid" />
          </div>
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/allanal.com.png'} alt="All Anal" className="d-block mx-auto img-fluid" />
          </div>
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/analonly.com.png'} alt="All Anal" className="d-block mx-auto img-fluid" />
          </div>
          <div className="col-sm-2 col-6">
            <img src={process.env.PUBLIC_URL + 'images/logos/dirtyauditions.com.png'} alt="Dirty Auditions" className="d-block mx-auto img-fluid" />
          </div>
        </div>
      </div>
    )
  }
}