import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="mt-5 mb-2 footer">
        <div className="row align-items-center">
          <div className="col text-center">
            <Link to="/2257" className="text-muted">
              18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col text-center mt-2">
            <Moment format="YYYY" /> MikeAdriano.com
          </div>
        </div>
      </div>
    )
  }
}