import React from 'react';

export default class GreenBar extends React.Component {
  render() {
    return (
        <div className="row justify-content-center greenbar py-1">
          <div className="col-sm-5 col-md-8 col-lg-7 col-xl-5 align-self-center text-center text-sm-right text-md-center text-lg-right">
            <span className="font-weight-bold">EXCLUSIVE OFFER! GET ACCESS TO ALL 4 SITES!</span>
          </div>
          <div className="col-sm-3 pl-1 text-center text-sm-left">
            <a href="#" className="btn btn-warning px-4 py-0">CLICK HERE</a>
          </div>
        </div>
    )
  }
}
