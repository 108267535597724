import React from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Home extends React.Component {
  state = {
    sites: []
  }

  componentDidMount() {
    
    const sites = [
                      {
                        'domain': 'swallowed.com',
                        'join_url': 'https://access.swallowed.com/track/OS4xLjEuMS4yNi4wLjAuMC4w',
                        'index': 0,
                      },
                      {
                        'domain': 'trueanal.com',
                        'join_url': 'https://access.trueanal.com/track/OS4xLjMuMy4yNi4wLjAuMC4w',
                        'index': 1,
                      },
                      {
                        'domain': 'nympho.com',
                        'join_url': 'https://access.nympho.com/track/OS4xLjQuNC4yNi4wLjAuMC4w',
                        'index': 2,
                      },
                      {
                        'domain': 'allanal.com',
                        'join_url': 'https://access.allanal.com/track/OS4xLjUuNS4yNi4wLjAuMC4w',
                        'index': 3,
                      },
                      {
                        'domain': 'analonly.com',
                        'join_url': 'https://access.analonly.com/track/OS4xLjYuMTAuMjYuMC4wLjAuMA',
                        'index': 4,
                      },
                      {
                        'domain': 'dirtyauditions.com',
                        'join_url': 'http://access.dirtyauditions.com/track/OS4xLjcuMTQuMjYuMC4wLjAuMA',
                        'index': 5,
                      }
                  ];
    this.setState({ sites });
  }

  render() {
    return (
      <div>
        <h5 className="text-center mt-4 mb-0 font-weight-bold">SITE'S LATEST UPDATES</h5>
        <div className="row">
          { this.state.sites.map( site => <ContentThumb key={site.domain} site={site} />)}
        </div>
      </div>
    )
  }
}

class ContentThumb extends React.Component {

  state = {
    content: {}
  }

  componentDidMount() {
    const subdomain = this.props.site.domain == 'dirtyauditions.com' ? '': 'tour.'
    axios.get('https://' + subdomain + this.props.site.domain + '/latest-json')
      .then(res => {
        const content = res.data;
        this.setState({ content })
      });
  }

  render() {
    
    return (
      <div className="col-sm-6" >
        <div className="thumb-container my-3">
          <div className="thumb">
            <a href={this.props.site.domain == 'dirtyauditions.com' ? this.props.site.join_url : `${this.props.site.join_url}/scenes/${this.state.content.slug}` } target="_blank" rel="noopener noreferrer">
              <img src={this.props.site.domain == 'dirtyauditions.com' && this.state.content.thumb ? this.state.content.thumb.replace('.jpg','-c538x354.jpg') : this.state.content.thumb} alt={this.state.content.title} className="img-fluid" />
            </a>
          </div>
          <div className="thumb-logo py-1">
            <div className="row justify-content-around align-items-center">
              <div className="col-sm-4 offset-sm-1 col-10 my-2">
                <img src={process.env.PUBLIC_URL + 'images/logos/' + this.props.site.domain + '.png'} alt={this.props.site.domain} className="d-block mx-auto img-fluid" />
              </div>
              <div className="col-sm-6"> 
                <a href={this.props.site.join_url} target="_blank" rel="noopener noreferrer" className="btn btn-success btn-block">VISIT SITE</a>
              </div>
            </div>
          </div>
          <div className="meta-container p-2">
            <div className="row">
              <div className="col">
                <div className="title font-weight-bold text-truncate text-uppercase">{ this.state.content.title }</div>
                <div className="models font-weight-bold">Model: { (this.state.content.models || []).join(', ')}</div>
                <div className="meta-data mt-2">
                  <div className="row justify-content-start">
                    <div className="col col-sm-4">
                      <FontAwesomeIcon icon={['far','calendar-alt']} className="mr-1" />
                      <Moment parse="YYYY-MM-DD HH:mm:ss" format="Do MMM YYYY">
                        {this.state.content.publish_date}
                      </Moment>
                    </div>
                    {this.state.content.photos_duration > 0 &&
                    <div className="col col-sm-2">
                    <FontAwesomeIcon icon={['far','image']} className="mr-1" />
                    {this.state.content.photos_duration}
                    </div>
                    }
                    <div className="col col-sm-3">
                    <FontAwesomeIcon icon={['far','play-circle']} className="mr-1" />
                    {this.state.content.videos_duration}
                    </div>
                  </div>
                </div>
                <div className="breaker mt-1"></div>
                <div className="description text-justify mt-1 mb-3">
                  {ReactHtmlParser(this.state.content.description)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}